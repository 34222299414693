import React, { Component } from 'react'
import {
  Segment,
  Input,
  Grid,
  Button,
  Icon,
  Responsive
} from 'semantic-ui-react'
import axios from 'helpers/axios.helper.js'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import Totals from './components/Totals'
import ByDate from './components/ByDate'
import ByMarketing from './components/ByMarketing'
import ByZipCode from './components/ByZipCode'
// import ByPromoCode from './components/ByPromoCode'
// import ByTicketType from './components/ByTicketType'
// import RedeemedByTicketType from './components/RedeemedByTicketType'
// import RedeemedByTimeSlot from './components/RedeemedByTimeSlot'
import { connect } from 'react-redux'

class Overview extends Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      stats: undefined,
      start: moment().subtract(1, 'week'),
      end: moment()
    }
  }

  componentDidMount() {
    this.getStats.apply(this)
  }

  getStats() {
    this.setState({ loading: true })

    axios()
      .get('/transactions/statistics', {
        params: {
          start: this.state.start.format(),
          end: this.state.end.format()
        }
      })
      .then(res => {
        this.setState({
          loading: false,
          stats: res
        })
      })
      .catch(err => {
        this.setState({ loading: false })
        toast.error(err.message)
      })
  }

  render() {
    return (
      <div style={{ marginTop: 14 }}>
        <Segment>
          <h2>Overview</h2>
          <Grid stackable>
            <Grid.Column width={6}>
              <DatePicker
                showMonthDropdown
                selected={this.state.start}
                selectsStart
                startDate={this.state.start}
                endDate={this.state.end}
                dateFormat="LL"
                onChange={date => this.setState({ start: date })}
                useShortMonthInDropdown
                showYearDropdown
                scrollableYearDropdown
                withPortal
                customInput={<Input fluid icon="calendar" />}
              />
            </Grid.Column>
            <Responsive
              as={Grid.Column}
              minWidth={800}
              width={1}
              textAlign="center"
              verticalAlign="middle"
            >
              <Icon name="long arrow alternate right" size="large" />
            </Responsive>
            <Grid.Column width={6}>
              <DatePicker
                showMonthDropdown
                selected={this.state.end}
                selectsEnd
                startDate={this.state.start}
                endDate={this.state.end}
                dateFormat="LL"
                onChange={date => this.setState({ end: date })}
                useShortMonthInDropdown
                showYearDropdown
                scrollableYearDropdown
                withPortal
                customInput={<Input fluid icon="calendar" />}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <Button
                fluid
                content="Search"
                loading={this.state.loading}
                onClick={this.getStats.bind(this)}
                icon="search"
              />
            </Grid.Column>
          </Grid>
        </Segment>
        <Totals data={this.state.stats && this.state.stats.totals} />
        <ByDate data={this.state.stats && this.state.stats.byDate} />
        <Grid columns="equal" stackable>
          <Grid.Column>
            <ByMarketing
              data={this.state.stats && this.state.stats.byMarketingMedium}
            />
          </Grid.Column>
          <Grid.Column>
            <ByZipCode data={this.state.stats && this.state.stats.byZipCode} />
          </Grid.Column>
        </Grid>
        {/* <Grid columns="equal" stackable>
          <Grid.Column>
            <ByPromoCode
              data={this.state.stats && this.state.stats.byPromoCode}
            />
          </Grid.Column>
          <Grid.Column>
            <ByTicketType
              data={this.state.stats && this.state.stats.byTicketType}
            />
          </Grid.Column>
        </Grid>
        <Grid columns="equal" stackable>
          <Grid.Column>
            <RedeemedByTicketType
              data={this.state.stats && this.state.stats.redeemedByTicketType}
            />
          </Grid.Column>
          <Grid.Column>
            <RedeemedByTimeSlot
              data={this.state.stats && this.state.stats.redeemedByTimeSlot}
            />
          </Grid.Column>
        </Grid> */}
      </div>
    )
  }
}

export default connect(state => ({
  company: state.auth.company
}))(Overview)
