import React, { Component } from 'react'
import {
  Table,
  Pagination,
  Icon,
  Button,
  Responsive,
  Confirm
} from 'semantic-ui-react'
import moment from 'moment-timezone'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import EditPromoCode from '../EditPromoCode'

class UsersTable extends Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      deleteConfirm: false,
      restoreConfirm: false,
      selected: undefined,
      editPromoCodeModal: false
    }
  }

  edit(disable) {
    this.setState({ loading: true })
    axios()
      .patch('/promo-codes/' + this.state.selected.id, {
        disabled: disable
      })
      .then(res => {
        this.props.getPromoCodes()
        this.setState({
          loading: false,
          deleteConfirm: false,
          restoreConfirm: false
        })
      })
      .catch(err => {
        this.setState({ loading: false })
        toast.error(err.message)
      })
  }

  render() {
    return (
      <Table striped textAlign={window.innerWidth > 800 ? 'center' : undefined}>
        <Confirm
          onCancel={() => this.setState({ deleteConfirm: false })}
          onConfirm={this.edit.bind(this, true)}
          size="tiny"
          content="Are you sure you want to delete this promo code?"
          header="Are you sure?"
          confirmButton={{
            loading: this.state.loading,
            negative: true,
            content: 'Delete it!',
            icon: 'trash'
          }}
          open={this.state.deleteConfirm}
        />

        <Confirm
          onCancel={() => this.setState({ restoreConfirm: false })}
          onConfirm={this.edit.bind(this, false)}
          size="tiny"
          content="Are you sure you want to restore this promo code?"
          header="Are you sure?"
          confirmButton={{
            loading: this.state.loading,
            positive: true,
            content: 'Restore it!',
            icon: 'undo'
          }}
          open={this.state.restoreConfirm}
        />

        <EditPromoCode
          ticketTypes={this.props.ticketTypes}
          selected={this.state.selected}
          open={this.state.editPromoCodeModal}
          onClose={() => this.setState({ editPromoCodeModal: false })}
        />

        <Responsive minWidth="800" as={Table.Header}>
          <Table.Row>
            <Table.HeaderCell textAlign="left">Phrase</Table.HeaderCell>
            <Table.HeaderCell>Discount</Table.HeaderCell>
            <Table.HeaderCell>Purchase By</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Responsive>

        <Table.Body>
          {!this.props.promoCodes.length && (
            <Table.Row>
              <Table.Cell colSpan="100">No promo codes found.</Table.Cell>
            </Table.Row>
          )}

          {this.props.promoCodes.map(promoCode => (
            <Table.Row key={promoCode.id}>
              <Table.Cell textAlign="left">
                {promoCode.disabled ? <Icon color="red" name="ban" /> : null}
                <strong>{promoCode.code}</strong>
              </Table.Cell>
              <Table.Cell>
                {Boolean(promoCode.discountPercentage) &&
                  `${promoCode.discountPercentage}%`}
                {Boolean(promoCode.discountAmount) &&
                  `$${promoCode.discountAmount.toFixed(2)}`}
                {window.innerWidth < 800 && ' Discount'}
              </Table.Cell>
              <Table.Cell>
                {promoCode.expiresAt && (
                  <span
                    style={{
                      color: moment().isAfter(promoCode.expiresAt) && 'red'
                    }}
                  >
                    {moment(promoCode.expiresAt).format('LLL')}
                  </span>
                )}

                {!promoCode.expiresAt && window.innerWidth > 800 && '-'}
              </Table.Cell>
              <Table.Cell
                onClick={() => this.setState({ selected: promoCode })}
                textAlign="right"
              >
                <Button
                  size="small"
                  compact
                  onClick={() => this.setState({ editPromoCodeModal: true })}
                  content="View Details"
                />
                {!promoCode.disabled ? (
                  <Button
                    size="small"
                    icon="trash"
                    compact
                    color="red"
                    onClick={() => this.setState({ deleteConfirm: true })}
                    content="Delete"
                  />
                ) : (
                  <Button
                    size="small"
                    icon="undo"
                    compact
                    color="green"
                    onClick={() => this.setState({ restoreConfirm: true })}
                    content="Restore"
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="100" textAlign="right">
              <Pagination
                onPageChange={this.props.changePage}
                nextItem={{ content: <Icon name="chevron right" /> }}
                prevItem={{ content: <Icon name="chevron left" /> }}
                lastItem={null}
                firstItem={null}
                ellipsisItem={null}
                boundaryRange={0}
                size="mini"
                activePage={this.props.currentPage}
                totalPages={this.props.totalPages}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    )
  }
}

export default UsersTable
