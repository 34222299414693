import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Segment, Input, Button, Dimmer, Loader, Grid } from 'semantic-ui-react'
import moment from 'moment-timezone'
import axios from 'helpers/axios.helper'
import DatePicker from 'react-datepicker'
import NewTimeSlot from './components/NewTimeSlot'
import Table from './components/Table'
import { toast } from 'react-toastify'
import EnableTimedTicketing from './components/EnableTimedTicketing'
import Footer from './components/Footer'

class TimeSlots extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      selectedDay: moment(),
      timeSlots: [],
      days: [],
      newTimeSlotModal: false,
      selectedMonth: new Date().getMonth() + 1,
      selectedYear: new Date().getFullYear(),
      includeDisabled: false,
      ticketTypes: []
    }
  }

  componentDidMount() {
    this.getTimeSlots()

    axios()
      .get('/ticket-types/' + this.props.company.id, {
        params: {
          includeDisabled: false
        }
      })
      .then(res => {
        this.setState({
          loading: false,
          ticketTypes: res
        })
      })
      .catch(err => {
        this.setState({ loading: false })
        toast.error(err.message)
      })
  }

  getTimeSlots() {
    this.setState({ loading: true })
    axios()
      .get('/time-slots/' + this.props.company.id, {
        params: {
          month: this.state.selectedMonth,
          year: this.state.selectedYear,
          includeDisabled: this.state.includeDisabled
        }
      })
      .then(res => {
        const getDaysBetween = (start, end) => {
          let day = start
          const days = []
          while (day.isBetween(start, end, 'day', '[]')) {
            days.push(day)
            day = day.clone().add(1, 'day')
          }
          return days
        }

        const days = res.reduce((arr, timeSlot) => {
          const start = moment(timeSlot.startAt)
          const end = moment(timeSlot.endAt)
          return [...arr, ...getDaysBetween(start, end)]
        }, [])

        this.setState({
          loading: false,
          timeSlots: res,
          days: days
        })
      })
      .catch(err => {
        this.setState({ loading: false })
        toast.error(err.message)
      })
  }

  handleMonthChange(date) {
    this.setState(
      {
        selectedMonth: date.month() + 1,
        selectedYear: date.year()
      },
      this.getTimeSlots
    )
  }

  handleDateChange(date) {
    this.setState({ selectedDay: date })
  }

  toggleShowDeleted() {
    this.setState(
      {
        includeDisabled: !this.state.includeDisabled
      },
      this.getTimeSlots
    )
  }

  render() {
    if (!this.props.company.usesTimedTicketing) {
      return <EnableTimedTicketing />
    }

    return (
      <Segment>
        <h2>Time Slots</h2>

        <NewTimeSlot
          open={this.state.newTimeSlotModal}
          currentDate={this.state.selectedDay}
          ticketTypes={this.state.ticketTypes}
          getTimeSlots={this.getTimeSlots.bind(this)}
          onClose={() => this.setState({ newTimeSlotModal: false })}
        />

        <Dimmer inverted active={this.state.loading}>
          <Loader />
        </Dimmer>

        <Grid stackable reversed="mobile" padded={false}>
          <Grid.Column computer={3} tablet={4}>
            <DatePicker
              onMonthChange={this.handleMonthChange.bind(this)}
              onYearChange={this.handleMonthChange.bind(this)}
              showMonthDropdown
              selected={this.state.selectedDay}
              dateFormat="LL"
              onChange={this.handleDateChange.bind(this)}
              useShortMonthInDropdown
              showYearDropdown
              scrollableYearDropdown
              highlightDates={this.state.days}
              withPortal
              customInput={<Input fluid icon="calendar" />}
            >
              <Dimmer active={this.state.loading} inverted>
                <Loader />
              </Dimmer>
            </DatePicker>
          </Grid.Column>
          <Grid.Column computer={3} tablet={4}>
            <Button
              fluid
              color="red"
              onClick={this.toggleShowDeleted.bind(this)}
              content={
                this.state.includeDisabled ? 'Hide Deleted' : 'Show Deleted'
              }
            />
          </Grid.Column>
          <Grid.Column computer={3} tablet={4} floated="right">
            <Button
              fluid
              color="blue"
              icon="add"
              onClick={() => this.setState({ newTimeSlotModal: true })}
              content="New Time Slot"
            />
          </Grid.Column>
        </Grid>

        <Table
          timeSlots={this.state.timeSlots.filter(timeSlot =>
            this.state.selectedDay.isBetween(
              moment(timeSlot.startAt),
              moment(timeSlot.endAt),
              'day',
              '[]'
            )
          )}
          ticketTypes={this.state.ticketTypes}
          getTimeSlots={this.getTimeSlots.bind(this)}
        />

        <Footer />
      </Segment>
    )
  }
}

export default connect(state => ({
  company: state.auth.company
}))(TimeSlots)
