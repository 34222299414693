import React, { Component } from 'react'
import { Table, Icon, Button, Confirm } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import EditTimeSlot from '../EditTimeSlot'
import TimeSlot from 'components/TimeSlot'

class TimeSlotsTable extends Component {
  constructor() {
    super()

    this.state = {
      loading: false,
      deleteConfirm: false,
      restoreConfirm: false,
      selected: undefined,
      editTimeSlot: false
    }
  }

  edit(disable) {
    this.setState({ loading: true })
    axios()
      .patch('/time-slots/' + this.state.selected.id, {
        disabled: disable
      })
      .then(res => {
        this.props.getTimeSlots()
        this.setState({
          loading: false,
          deleteConfirm: false,
          restoreConfirm: false
        })
      })
      .catch(err => {
        this.setState({ loading: false })
        toast.error(err.message)
      })
  }

  render() {
    return (
      <Table striped>
        <Confirm
          onCancel={() => this.setState({ deleteConfirm: false })}
          onConfirm={this.edit.bind(this, true)}
          size="tiny"
          content="Are you sure you want to delete this time slot?"
          header="Are you sure?"
          confirmButton={{
            loading: this.state.loading,
            negative: true,
            content: 'Delete it!',
            icon: 'trash'
          }}
          open={this.state.deleteConfirm}
        />

        <Confirm
          onCancel={() => this.setState({ restoreConfirm: false })}
          onConfirm={this.edit.bind(this, false)}
          size="tiny"
          content="Are you sure you want to restore this time slot?"
          header="Are you sure?"
          confirmButton={{
            loading: this.state.loading,
            positive: true,
            content: 'Restore it!',
            icon: 'undo'
          }}
          open={this.state.restoreConfirm}
        />

        <EditTimeSlot
          getTimeSlots={this.props.getTimeSlots}
          open={this.state.editTimeSlot}
          ticketTypes={this.props.ticketTypes}
          timeSlot={this.state.selected}
          onClose={() => this.setState({ editTimeSlot: false })}
        />

        <Table.Body>
          {!this.props.timeSlots.length && (
            <Table.Row>
              <Table.Cell textAlign="center">
                <h4>
                  No time slots found for the selected day. Create a new time
                  slot or select a different day.
                </h4>
              </Table.Cell>
            </Table.Row>
          )}
          {this.props.timeSlots.map(timeSlot => (
            <Table.Row key={timeSlot.id}>
              <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
                {timeSlot.disabled && <Icon name="ban" color="red" />}
                <TimeSlot timeSlot={timeSlot} />
              </Table.Cell>
              <Table.Cell textAlign="center">
                <div>
                  {timeSlot.numberSold || 0} Sold
                  <br />
                  <span>{timeSlot.numberRedeemed} Redeemed</span>
                  <br />
                  {timeSlot.numberAvailable !== null && (
                    <span>{timeSlot.numberLeft} Remaining</span>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell
                onClick={() => this.setState({ selected: timeSlot })}
                textAlign="right"
              >
                <Button
                  size="small"
                  compact
                  onClick={() => this.setState({ editTimeSlot: true })}
                  icon="pencil alternate"
                  content="Edit"
                />
                {!timeSlot.disabled ? (
                  <Button
                    size="small"
                    icon="trash"
                    compact
                    color="red"
                    onClick={() => this.setState({ deleteConfirm: true })}
                    content="Delete"
                  />
                ) : (
                  <Button
                    size="small"
                    icon="undo"
                    compact
                    color="green"
                    onClick={() => this.setState({ restoreConfirm: true })}
                    content="Restore"
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    )
  }
}

export default TimeSlotsTable
